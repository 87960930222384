<template>

    <div v-if="view == 'mobile'">

        <router-link class="box flex flex-col h-24 w-11/12 shadow-md" :to="{name: 'ProjectsDetail', params: { id: alert.id }}">

            <div class="flex flex-col mb-2">

                <div class="h-auto flex flex-row justify-start items-center">

                    <div class="h-full w-9/12 flex flex-row justify-start items-center">
                        <div class="text-xxs font-semibold truncate">{{ alert.project_name }}</div>
                    </div>

                    <div class="h-full w-3/12 flex flex-row justify-end items-center">
                        <div class="text-font-dark text-3xs ml-auto">#{{ alert.project_link }}</div>    
                    </div>

                </div>

                <div class="h-auto flex flex-row justify-between items-center mt-1">

                    <span class="text-3xs text-font-gray text-left">
                        Client: {{ alert.client_name }}
                    </span>

                    <span class="text-3xs text-xs ml-4 font-bold uppercase">{{ alert.status }}</span>

                </div>

            </div>

            <div class="flex-1 min-h-0 flex flex-row">

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.ner_vs_initial">
                    <span class="text-3xs text-font-gray mb-1">Actual ANSR vs Initial</span>
                    <span class="text-xs font-semibold">{{ alert.ner_vs_initial | reduceBigNumbers(2) }}€</span>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.future_ner_vs_initial">
                    <span class="text-3xs text-font-gray mb-1 text-center">Actual ANSR+ Forecasts vs Initial</span>
                    <span class="text-xs font-semibold" v-bind:class="{ 'text-danger': alert.is_positive_alert!==true, 'text-success': alert.is_positive_alert }">
                        {{ alert.future_ner_vs_initial | reduceBigNumbers(2) }}
                    </span>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.margin_current!== undefined">
                    <span class="text-3xs text-font-gray mb-1">Current</span>
                    <span class="text-xs font-semibold">{{ alert.margin_current || 0 | reduceBigNumbers(2) }}%</span>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.initial_vs_current_margin!== undefined">
                    <div class="text-3xs text-font-gray mb-1">Initial vs Current</div>
                    <div class="text-xs font-semibold" v-bind:class="{ 'text-danger': alert.is_positive_alert!==true, 'text-success': alert.is_positive_alert }">{{ alert.initial_vs_current_margin || 0 | numberFormat(2)}} ptos.</div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.unbilled">
                    <div class="text-3xs text-font-gray mb-1">Unbilling</div>
                    <div class="text-xs font-semibold">{{ alert.unbilled }}</div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_to_payment || alert.days_to_payment === null">
                    <div class="text-3xs text-font-gray mb-1">Days to payment</div>
                    <div class="text-xs font-semibold text-danger">
                        <template v-if="alert.days_to_payment">
                            {{ alert.days_to_payment }}d
                        </template>
                        <template v-else>N/A</template>
                    </div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_to_provision">
                    <div class="text-3xs text-font-gray mb-1">Days to provision</div>
                    <div class="text-xs font-semibold text-danger">{{ alert.days_to_provision }}d</div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center"
                        v-if="alert.days_without_change || alert.days_without_change === null">
                    <div class="text-3xs text-font-gray mb-1">Days without change</div>
                    <div class="text-xs font-semibold">
                        <template v-if="alert.days_without_change">
                            {{ alert.days_without_change }}d
                        </template>
                        <template v-else>N/A</template>
                    </div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center"
                        v-if="alert.days_to_default || alert.days_to_default === null">
                    <div class="text-3xs text-font-gray mb-1">Days to default</div>
                    <div class="text-xs font-semibold text-danger">
                        <template v-if="alert.days_to_default">
                            {{ alert.days_to_default }}d
                        </template>
                        <template v-else>N/A</template>
                    </div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.period_payment !== undefined">
                    <div class="text-3xs text-font-gray mb-1">PMC</div>
                    <div class="text-xs font-semibold">{{ alert.period_payment || 0 | numberFormat(0) }}</div>
                </div>

                <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.as_reserves !== undefined">
                    <div class="text-3xs text-font-gray mb-1">As Reserves</div>
                    <div class="text-xs font-semibold">{{ alert.as_reserves || 0 | numberFormat(0) }}</div>
                </div>

            </div>

        </router-link>

    </div>

    <div v-else>

        <router-link class="box block h-32" :to="{ name:'ProjectsBillingChild', params: { projectId: alert.id }}" >
            
                <div class="h-8 flex flex-row justify-between items-center">

                    <div class="h-full w-2/3 flex flex-row justify-start items-center">

                        <span class="text-white font-semibold truncate text-sm">{{ alert.project_name }}</span>

                    </div>

                    <div class="h-full w-1/3 flex flex-row justify-end items-center">

                        <span class="text-font-gray text-xs font-semibold">#{{ alert.project_link }}</span>

                    </div>


                </div>

                <div class="h-8 flex flex-row justify-between items-center">

                    <div class="h-full w-2/3 flex flex-row justify-start items-center break-words">
                        <span class="text-font-gray text-xs truncate">Client: {{ alert.client_name }}</span>
                    </div>

                    <div class="h-full w-1/3 flex flex-row justify-end items-center">
                        <span class="text-white capitalize font-semibold text-xs mr-3">{{ alert.status }}</span>
                    </div>

                </div>

                <div class="flex-1 min-h-0 flex flex-row">

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.ner_vs_initial">
                        <span class="text-xs text-font-gray mb-1">Actual ANSR vs Initial</span>
                        <span class="text-xl font-semibold">{{ alert.ner_vs_initial | reduceBigNumbers(2) }}€</span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center" v-if="alert.future_ner_vs_initial">
                        <span class="text-xs text-font-gray mb-1">Actual ANSR+ Forecasts vs Initial</span>
                        <span class="text-xl font-semibold" v-bind:class="{ 'text-danger': alert.is_positive_alert!==true, 'text-success': alert.is_positive_alert }">
                            {{ alert.future_ner_vs_initial  | reduceBigNumbers(2) }}€
                        </span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.margin_current!== undefined">
                        <span class="text-xs text-font-gray mb-1">Current</span>
                        <span class="text-xl font-semibold">{{ alert.margin_current || 0 | numberFormat(2)}}%</span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.initial_vs_current_margin!== undefined">
                        <span class="text-xs text-font-gray mb-1">Initial vs Current</span>
                        <span class="text-xl font-semibold" v-bind:class="{ 'text-danger': alert.is_positive_alert!==true, 'text-success': alert.is_positive_alert }">{{ alert.initial_vs_current_margin || 0 | numberFormat(2)}} ptos.</span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.unbilled">
                        <span class="text-xs text-font-gray mb-1">Unbilling</span>
                        <span class="text-xl font-semibold">{{ alert.unbilled }}</span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_to_payment || alert.days_to_payment === null">
                        <span class="text-xs text-font-gray mb-1">Days to payment</span>
                        <span class="text-xl font-semibold text-danger">
                            <span v-if="alert.days_to_payment">
                                {{ alert.days_to_payment }}d
                            </span>
                            <span v-else>N/A</span>
                        </span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_to_provision">
                        <div class="text-xs text-font-gray mb-1">Days to provision</div>
                        <div class="text-xl font-semibold text-danger">{{ alert.days_to_provision }}d</div>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_without_change || alert.days_without_change === null">
                        <span class="text-xs text-font-gray mb-1">Days without change</span>
                        <span class="text-xl font-semibold">
                            <span v-if="alert.days_without_change">
                                {{ alert.days_without_change }}d
                            </span>
                            <span v-else>N/A</span>
                        </span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.days_to_default || alert.days_to_default === null">
                        <span class="text-xs text-font-gray mb-1">Days to default</span>
                        <span class="text-xl font-semibold text-danger">
                            <span v-if="alert.days_to_default">
                                {{ alert.days_to_default }}d
                            </span>
                            <span v-else>N/A</span>
                        </span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.as_reserves !== undefined">
                        <span class="text-xs text-font-gray mb-1">As Reserves</span>
                        <span class="text-xl font-semibold">{{ alert.as_reserves || 0 | numberFormat(0) }}</span>
                    </div>

                    <div class="h-full w-full flex flex-col justify-center items-center" v-if="alert.period_payment !== undefined">
                        <span class="text-xs text-font-gray mb-1">PMC</span>
                        <span class="text-xl font-semibold">{{ alert.period_payment || 0 | numberFormat(0) }}</span>
                    </div>

                </div>

        </router-link>

    </div>

</template>

<script>
export default {
    props:['alert', 'view']
}
</script>